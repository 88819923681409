body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }


  .css-y1n8i3-MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, .css-y1n8i3-MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: unset !important;
  }